import Vue from 'vue'
import { BROWSE_JOBS, SERVICES } from '@/constants/routes'

export default Vue.extend({
  name: 'LaborxPlatform',
  serverCacheKey: () => 'LaborxPlatform',
  data () {
    return {
      BROWSE_JOBS,
      SERVICES,
    }
  }
})
