import { render, staticRenderFns } from "./NewGigs.htm?vue&type=template&id=60968baf&scoped=true&"
import script from "./NewGigs.ts?vue&type=script&lang=js&"
export * from "./NewGigs.ts?vue&type=script&lang=js&"
import style0 from "./NewGigs.scss?vue&type=style&index=0&id=60968baf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60968baf",
  null
  
)

export default component.exports