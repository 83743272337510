import { render, staticRenderFns } from "./ProfitsSection.htm?vue&type=template&id=2748785d&scoped=true&"
import script from "./ProfitsSection.ts?vue&type=script&lang=js&"
export * from "./ProfitsSection.ts?vue&type=script&lang=js&"
import style0 from "./ProfitsSection.scss?vue&type=style&index=0&id=2748785d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2748785d",
  null
  
)

export default component.exports