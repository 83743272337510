import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import Skill from '@/models-ts/Skill'
import VacancyListItem from '@/models-ts/vacancies/VacancyListItem'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import { DATE_TIME_FORMAT } from '@/constants/utils/date'
import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import { BROWSE_VACANCIES_BY_ROLE, BROWSE_VACANCIES_BY_SKILL, CUSTOMER_PROFILE, VACANCY_DETAILS } from '@/constants/routes'
import { formatDate, getHumanDate } from '@/utils/date'
import { formatUsd } from '@/utils/moneyFormat'
import rolebleMixin from '@/mixins/rolebleMixin'
import BookmarkIconButton from '@/partials/BookmarkButtons/BookmarkIconButton/BookmarkIconButton.vue'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'
import UserVerificationBadge from '@/partials/StatusBadges/UserVerificationBadge/UserVerificationBadge.vue'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  name: 'LandingVacancyCard',
  serverCacheKey: props => `LandingVacancyCard${props.vacancy.id}`,
  mixins: [rolebleMixin],
  components: { NoSsr, BookmarkIconButton, VacancyLocationTooltip, UserVerificationBadge },
  props: {
    vacancy: VacancyListItem,
    loading: Boolean,
  },
  data () {
    return {
      BookmarkTypes,
      CUSTOMER_PROFILE,
      VACANCY_DETAILS,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      ownUserId: (state: any) => state.user?.id,
    }),
    analyticsParams () {
      return {
        name: 'ftj-tag-click'
      }
    },
    userId () {
      return this.vacancy.user?.id
    },
    customerType () {
      return this.vacancy.user?.profile?.type
    },
    isOwnVacancy () {
      return this.ownUserId === this.userId
    },
    userAvatar () {
      return this.vacancy.user?.avatar
    },
    userType () {
      return this.vacancy.user?.type
    },
    userName () {
      return this.vacancy.user?.name
    },
    createdAt () {
      const date = this.vacancy.published_at || this.vacancy.updated_at || this.vacancy.created_at
      return {
        local: getHumanDate(date),
        utc: formatDate(date, DATE_TIME_FORMAT),
      }
    },
    positionType () {
      return POSITION_TYPES[this.vacancy.position_type as PositionType]?.name
    },
    salaryFrom () {
      const salaryFrom = new BigNumber(this.vacancy.salary_from).div(12)
      return salaryFrom.gt(100)
        ? formatUsd(salaryFrom, 0)
        : formatUsd(salaryFrom, 2)
    },
    salaryTo () {
      const salaryTo = new BigNumber(this.vacancy.salary_to).div(12)
      return salaryTo.gt(100)
        ? formatUsd(salaryTo, 0)
        : formatUsd(salaryTo, 2)
    },
    salary () {
      return this.vacancy.salary_type === SalaryTypes.FIXED
        ? this.salaryFrom
        : `${this.salaryFrom}-${this.salaryTo}`
    },
    roleTag () {
      return this.vacancy.primaryRole?.id
        ? {
          text: this.vacancy.primaryRole.name,
          link: { name: BROWSE_VACANCIES_BY_ROLE, params: { role: this.vacancy.primaryRole.url } },
        }
        : null
    },
    skills () {
      const skills = this.vacancy.skills
        .map((skill: Skill) => ({
          text: skill.name,
          link: { name: BROWSE_VACANCIES_BY_SKILL, params: { skill: skill.url } },
        }))
      return this.roleTag
        ? [this.roleTag, ...skills]
        : skills
    },
    skillsSlice () {
      return process.client && !this.xsAndDown ? 3 : 1
    },
    remoteInfo () {
      return this.vacancy.remoteInfo
    },
  },
})
