import { render, staticRenderFns } from "./LaborxPlatform.htm?vue&type=template&id=a7ce8c24&scoped=true&"
import script from "./LaborxPlatform.ts?vue&type=script&lang=js&"
export * from "./LaborxPlatform.ts?vue&type=script&lang=js&"
import style0 from "./LaborxPlatform.scss?vue&type=style&index=0&id=a7ce8c24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7ce8c24",
  null
  
)

export default component.exports