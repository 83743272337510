import Vue from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { BROWSE_JOBS, POST_A_JOB, VACANCIES } from '@/constants/routes'
import LandingMobileStepper from '../LandingMobileStepper/LandingMobileStepper.vue'
import { withCommas } from '@/utils/moneyFormat'
import JobCard from './JobCard/JobCard.vue'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  components: {
    JobCard,
    LandingMobileStepper,
  },
  data () {
    return {
      BROWSE_JOBS,
      POST_A_JOB,
      stepIndent: 0,
      scrollEndTimeout: null,
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState) => state.authorized,
    }),
    ...mapState('landing', {
      jobsCount: (state: any) => state.jobsCount,
    }),
    ...mapGetters({
      jobs: 'landing/jobListPublic',
    }),
    totalJobsFormatted () {
      return withCommas(String(this.jobsCount))
    },
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    if (this.scrollEndTimeout) {
      clearTimeout(this.scrollEndTimeout)
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    alignJobCard (behavior?: 'smooth' | 'auto') {
      const jobsContainer = this.$refs.freelancersContainer
      if (jobsContainer) {
        if (jobsContainer.scrollLeft > jobsContainer.clientWidth / 2 + 10) {
          this.scrollTo(1, behavior)
        } else {
          this.scrollTo(0, behavior)
        }
      }
    },
    scrollTo (slide: number, behavior?: 'smooth' | 'auto') {
      const jobsContainer = this.$refs.freelancersContainer
      if (jobsContainer) {
        if (slide === 1) {
          jobsContainer.scrollTo({ left: jobsContainer.scrollWidth, behavior })
        } else {
          jobsContainer.scrollTo({ left: 0, behavior })
        }
      }
    },
    onViewScroll: debounce(function (this: any) {
      if (this.scrollEndTimeout !== null) {
        clearTimeout(this.scrollEndTimeout)
      }
      this.scrollEndTimeout = setTimeout(() => {
        this.alignView('smooth')
      }, 150)
    }, 30),
    onResize: debounce(function (this: any) {
      this.alignJobCard('auto')
    }, 30),
    onScroll: throttle(function (this: any) {
      const jobsContainer = this.$refs.freelancersContainer
      if (jobsContainer) {
        this.stepIndent = (jobsContainer.scrollLeft / (jobsContainer.clientWidth + 20)) * 40
      }
    }, 30),
  },
})
