import { RootState } from '@/store'
import Vue from 'vue'
import { mapState } from 'vuex'
import { SERVICE_BY_SKILL, SKILLS_LIST } from '@/constants/routes'
import Skill from '@/models-ts/Skill'
import skillableMixin from '@/mixins/skillableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [ skillableMixin ],
  data () {
    return {
      SERVICE_BY_SKILL,
      SKILLS_LIST,
      isMoreActive: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      predefinedSkills: (state: RootState) => state.skills.skills.value,
    }),
    skillTags () {
      return this.predefinedSkills
        .slice(0, 50)
        .map((skill: Skill) => ({
          text: skill.name,
          link: skill.url,
        }))
    },
  },
  methods: {
    onClickMore () {
      this.isMoreActive = !this.isMoreActive
    },
  }
})
