export const META_COUNTRIES = [
  'USA',
  'Great Britain',
  'Germany',
  'Australia',
  'Austria',
  'Spain',
  'India',
  'Pakistan',
  'Nigeria',
  'Canada',
  'France',
  'Brazil',
  'Bulgaria',
  'Philippines',
  'Singapore',
  'Netherlands',
  'Turkey',
  'Switzerland',
  'Italy',
  'Iran',
  'Portugal',
  'South Africa',
  'Mexico',
  'Indonesia',
  'Colombia',
  'Korea',
  'United Arab Emirates',
  'Norway',
  'Hong Kong',
]
