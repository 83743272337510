import Vue from 'vue'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import pickBy from 'lodash/pickBy'
import { IBrowseVacanciesState } from '@/store/shared/modules/browse-vacancies/types'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'
import FilterCard from './FilterCard/FilterCard.vue'
import SubscribeCard from './SubscribeCard/SubscribeCard.vue'
import VacancyCard from './VacancyCard/VacancyCard.vue'

export const SORT_FEATURED = {
  name: 'Newest',
  field: 'featured',
  dir: 'desc',
}

export const SORT_NEW = {
  field: 'date',
  dir: 'desc',
}

export const VACANCIES_LIMIT = 38

export function getFilterFromQuery (query: any, setPagination: Function) {
  let {
    title,
    orderField,
    role,
    orderType,
    page,
  } = query
  let filtersCount = 0
  if (!page || Number.isNaN(Number(page)) || Number(page) < 1) {
    page = 1
  } else {
    page = Math.max(Number.parseInt(page), 1)
    filtersCount += 1
  }
  setPagination({ limit: VACANCIES_LIMIT, offset: (page - 1) * VACANCIES_LIMIT })
  title = title?.trim()
  if (title) filtersCount += 1
  const primaryRolesIds = [] as Array<string>
  if (role && !isNaN(role)) {
    primaryRolesIds.push(role)
  }
  if (primaryRolesIds.length) filtersCount += 1

  const hasntSortInQuery = !orderField || !orderType
  const defaultSort = orderField === SORT_NEW.field && orderType === SORT_NEW.dir

  if ((hasntSortInQuery || defaultSort) && filtersCount === 0) {
    orderField = SORT_FEATURED.field
    orderType = SORT_FEATURED.dir
  } else if (orderField === SORT_FEATURED.field && (filtersCount > 0 || orderType !== SORT_NEW.dir)) {
    orderField = SORT_NEW.field
    orderType = SORT_NEW.dir
  }

  return pickBy({
    title,
    orderField,
    orderType,
    primaryRolesIds,
  }, Boolean)
}

export default Vue.extend<any, any, any, any>({
  components: {
    FilterCard,
    SubscribeCard,
    VacancyCard,
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState): boolean => state.authorized,
    }),
    ...mapState<IBrowseVacanciesState>('browseVacancies', {
      isLoading: (state: IBrowseVacanciesState) => state.vacancies.isLoading,
      isLoaded: (state: IBrowseVacanciesState) => state.vacancies.isLoaded,
      pagination: (state: IBrowseVacanciesState) => state.vacancies.pagination,
      prefetched: (state: IBrowseVacanciesState) => state.prefetched,
    }),
    ...mapGetters({
      vacancies: 'browseVacancies/vacancies',
    }),
    currentPage () {
      let page: string = this.$route?.query?.page
      return Number.isNaN(Number(page))
        ? 1
        : Math.max(Number.parseInt(page), 1)
    },
    totalPages () {
      return Math.ceil(this.pagination.total / VACANCIES_LIMIT)
    },
    sectionTitle () {
      return this.currentPage > 1 ? `Crypto Jobs - Page ${this.currentPage}` : 'Crypto Jobs'
    },
    hasLoader () {
      return this.isLoading && !this.isLoaded
    }
  },
  watch: {
    $route: {
      async handler () {
        this.reloadVacancies()
      },
    },
  },
  methods: {
    ...mapActions({
      loadVacancies: 'browseVacancies/loadVacancies',
      openModal: 'ui/openModal',
    }),
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      resetState: 'browseVacancies/resetState',
      setPrefetched: 'browseVacancies/setPrefetched',
      setPagination: 'browseVacancies/setPagination',
    }),
    async reloadVacancies () {
      this.setPagination({ limit: VACANCIES_LIMIT, offset: 0 })
      await this.loadVacancies(getFilterFromQuery(this.$route.query, this.setPagination))
    },
    async onChangePage (page: number) {
      const filters = cloneDeep(pickBy({
        ...this.$route.query,
        page: page > 1 ? page : '',
      }, Boolean))
      let name = this.$route.name
      let params = {
        ...this.$route.params,
        savePosition: true,
      }
      this.$router.replace({
        name,
        params,
        query: filters,
      }).catch(() => {})
    },
  }
})
