import Vue from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import PublicFooter from '@/partials/PublicFooter/PublicFooter.vue'
import LxHeader from '@/partials/LxHeader/LxHeader.vue'
import SkillsMenu from '@/partials/SkillsMenu/SkillsMenu.vue'
import { DASHBOARD } from '@/constants/routes'
import { exchangeSocialTemporaryToken } from '@/api/usersAndProfiles/auth'
import { stripDescriptionTags } from '@/utils-ts/strings'
import Skill from '@/models-ts/Skill'
import { META_COUNTRIES } from '@/constants/meta'
import Benefits from './Benefits/Benefits.vue'
import Blog from './Blog/Blog.vue'
import BrowseVacanciesMini from './BrowseVacanciesMini/BrowseVacanciesMini.vue'
import Faq from './Faq/Faq.vue'
import FeaturedIn from './FeaturedIn/FeaturedIn.vue'
import GetPremium from './GetPremium/GetPremium.vue'
import LaborxPlatform from './LaborxPlatform/LaborxPlatform.vue'
import NewCryptoJobs from './NewCryptoJobs/NewCryptoJobs.vue'
import NewGigs from './NewGigs/NewGigs.vue'
import OurProducts from './OurProducts/OurProducts.vue'
import SeoJobLinks from './SeoJobLinks/SeoJobLinks.vue'
import FreelanceServices from './FreelanceServices/FreelanceServices.vue'
import Skills from './Skills/Skills.vue'
import Partners from './Partners/Partners.vue'
import { addMonths, formatDate } from '@/utils/date'
import { DATE_TIME_FORMAT_WITHOUT_SEC } from '@/constants/utils/date'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'
import VacancyLocation from '@/models-ts/vacancies/VacancyLocation'
import LandingJob from '@/models-ts/landing/LandingJob'
import canonicalMixin from '@/mixins/canonicalMixin'
import { IBrowseVacanciesState } from '@/store/shared/modules/browse-vacancies/types'
import { getFilterFromQuery, VACANCIES_LIMIT } from './BrowseVacanciesMini/BrowseVacanciesMini'

const metaTotal = '600+'
// eslint-disable-next-line max-len
const metaDescription = `Ready to take on the NEXT frontier in tech? 👨‍💻 Explore ${metaTotal} blockchain-based jobs in Web3, Cryptocurrency & Bitcoin 💵 Join the revolution & shape your future with our latest job listings today! Apply now!`

export default Vue.extend<any, any, any, any>({
  mixins: [canonicalMixin],
  name: 'Landing',
  props: {
    title: String,
    role: String,
    orderField: String,
    orderType: String,
    page: String,
  },
  components: {
    Benefits,
    Blog,
    BrowseVacanciesMini,
    Faq,
    FeaturedIn,
    FreelanceServices,
    GetPremium,
    LaborxPlatform,
    NewCryptoJobs,
    NewGigs,
    OurProducts,
    Partners,
    PublicFooter,
    LxHeader,
    SeoJobLinks,
    Skills,
    SkillsMenu,
  },
  data () {
    return {
      preloading: false,
      isMounted: false,
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState) => state.authorized,
    }),
    ...mapState('landing', {
      gigsCount: (state: any) => state.gigsCount,
      jobsCount: (state: any) => state.jobsCount,
    }),
    ...mapState<IBrowseVacanciesState>('browseVacancies', {
      isLoadingBrowseVacancies: (state: IBrowseVacanciesState) => state.vacancies.isLoading,
      isLoadedBrowseVacancies: (state: IBrowseVacanciesState) => state.vacancies.isLoaded,
      browseVacanciesPagination: (state: IBrowseVacanciesState) => state.vacancies.pagination,
      browseVacanciesPrefetched: (state: IBrowseVacanciesState) => state.prefetched,
    }),
    ...mapGetters({
      browseVacancies: 'browseVacancies/vacancies',
    }),
    ...mapGetters({
      posts: 'blog/lastPosts',
      jobs: 'landing/jobListPublic',
      gigs: 'landing/gigListPublic',
      vacancies: 'landing/vacancyListPublic',
    }),
    totalPages () {
      return Math.ceil(this.browseVacanciesPagination.total / VACANCIES_LIMIT)
    },
    currentPage () {
      let page: string = this.$route?.query?.page
      return Number.isNaN(Number(page))
        ? 1
        : Math.max(Number.parseInt(page), 1)
    },
    hasAdditionSection () {
      return this.isLoadedBrowseVacancies &&
        (this.browseVacanciesPagination.total <= VACANCIES_LIMIT || this.currentPage <= 1)
    },
    metaTitle () {
      return this.currentPage > 1
        ? `Crypto Jobs - Page ${this.currentPage}`
        : 'Crypto Jobs: Web3 & Blockchain & Cryptocurrency Jobs list'
    },
    metaDescription () {
      if (this.currentPage > 1) {
        // eslint-disable-next-line max-len
        return `Crypto Jobs - Page ${this.currentPage}. Join the revolution & shape your future with our latest job listings today! Apply now!`
      }
      return metaDescription
    },
    canonicalLink () {
      const url = this.getCanonicalUrl(this.$route)
      if (Number(url.searchParams.get('page')) === 1) {
        url.searchParams.delete('page')
      }
      return {
        vmid: 'canonical',
        rel: 'canonical',
        href: url.href,
      }
    },
  },
  async prefetch () {
    if (process.server) {
      const hasPageInQuery = 'page' in this.$route.query
      let pageFromQuery = Number(this.$route.query?.page || 1)
      pageFromQuery = pageFromQuery > 1 ? pageFromQuery : 1
      try {
        this.setPagination({ limit: VACANCIES_LIMIT, offset: 0 })
        await Promise.all([
          this.getLandingData(),
          this.loadVacancies(getFilterFromQuery(this.$route.query, this.setPagination))
        ])
        if (!hasPageInQuery) {
          this.setPrefetched(true)
        } else if (pageFromQuery > this.totalPages) {
          const curPage = this.totalPages > 1 ? this.totalPages : 0
          const url = new URL(this.$route.fullPath, process.env.VUE_APP_FRONTEND_URL)
          if (curPage) {
            url.searchParams.set('page', curPage)
          } else {
            url.searchParams.delete('page')
          }
          let newUrl = url.searchParams.toString()
          newUrl = newUrl ? `/?${newUrl}` : '/'
          this.setRedirect(newUrl)
        } else if (pageFromQuery === 1) {
          const url = new URL(this.$route.fullPath, process.env.VUE_APP_FRONTEND_URL)
          url.searchParams.delete('page')
          let newUrl = url.searchParams.toString()
          newUrl = newUrl ? `/?${newUrl}` : '/'
          this.setRedirect(newUrl)
        } else {
          this.setPrefetched(true)
        }
      } catch (e) {
        console.error('Prefetch vacancy error:', e)
      }
    }
  },
  async created () {
    if (process.client) {
      try {
        const promises = []
        if (!this.posts.length || !this.gigsCount || !this.jobsCount) {
          this.preloading = true
          promises.push(this.getLandingData())
        }
        if (!this.browseVacanciesPrefetched) {
          this.setPagination({ limit: VACANCIES_LIMIT, offset: 0 })
          promises.push(this.getRoles())
          promises.push(this.loadVacancies(getFilterFromQuery(this.$route.query, this.setPagination)))
        }
        if (promises.length > 0) {
          await Promise.all(promises)
        }
        this.setPrefetched(false)
      } catch (e) {
        this.parseError(e)
      } finally {
        this.preloading = false
      }
    }
  },
  async mounted () {
    this.isMounted = true
    if (this.$route.query.action && this.$route.query.action === 'reset') {
      this.openModal({
        component: 'lx-reset-modal',
        props: {
          token: this.$route.query.token
        }
      })
      this.$router.replace({ query: null })
    } else if (this.$route.query.action === '2fa') {
      const token = this.$route.query.token
      this.openModal({
        component: 'lx-confirm-2fa-modal',
        props: {
          confirm: async (key: string) => {
            const newToken = await exchangeSocialTemporaryToken(token, key)
            this.setToken(newToken.token)
            this.$router.push({ name: DASHBOARD })
          }
        }
      })
      this.$router.replace({ query: null })
    } else if (this.$route.query.token) {
      this.openModal({
        component: 'lx-sign-up-social-modal',
        props: {
          token: this.$route.query.token,
          authClient: this.$route.query.authclient,
          viaLogin: !!Number(this.$route.query.module),
        }
      })
      this.$router.replace({ query: null })
    } else if (this.$route.query.error) {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Error',
          text: this.$route.query.error,
        }
      })
      this.$router.replace({ query: null })
    } else if ((this.$route.query || {}).hasOwnProperty('sign-up') && !this.isLoggedIn) {
      this.openModal({
        component: 'lx-sign-up-modal',
        props: {
          fromType: 'fromType',
        }
      })
    }
  },
  beforeDestroy () {
    this.resetState()
  },
  methods: {
    ...mapActions({
      loadVacancies: 'browseVacancies/loadVacancies',
      getRoles: 'vacancyRoles/getRoles',
      openModal: 'ui/openModal',
      setToken: 'app/setToken',
      getLandingData: 'landing/getLandingData',
    }),
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      resetState: 'browseVacancies/resetState',
      setPrefetched: 'browseVacancies/setPrefetched',
      setPagination: 'browseVacancies/setPagination',
    }),
  },
  metaInfo () {
    const script = []
    const getBudgetUnit = (budget: number) => {
      if (budget < 50) {
        return 'HOUR'
      } else if (budget < 200) {
        return 'DAY'
      } else if (budget < 1000) {
        return 'WEEK'
      }
      return 'MONTH'
    }
    const defaultLocations = META_COUNTRIES.map(name => ({
      '@type': 'Country', name
    }))
    for (let job of this.jobs as LandingJob[]) {
      script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'JobPosting',
          title: job.name,
          description: stripDescriptionTags(job.description, { stripLinks: true }),
          employmentType: 'CONTRACTOR',
          jobLocationType: 'TELECOMMUTE',
          applicantLocationRequirements: defaultLocations,
          hiringOrganization: {
            '@type': 'Organization',
            name: job.user.name,
            // @ts-ignore
            ...(job.user.avatar?.src && { logo: job.user.avatar.src }),
            ...(job.userWebsite && { sameAs: job.userWebsite }),
          },
          occupationalCategory: job.skills.length > 0 ? job.skills[0].name : '',
          ...(job.skills.length > 1 && { skills: job.skills.slice(1).map((s: Skill) => s.name).join(', ') }),
          estimatedSalary: {
            '@type': 'MonetaryAmount',
            currency: 'USD',
            value: {
              '@type': 'QuantitativeValue',
              value: job.budget,
              unitText: getBudgetUnit(Number(job.budget)),
            },
          },
          datePosted: formatDate(job.publishedAt, 'YYYY-MM-DD'),
          validThrough: formatDate(addMonths(job.publishedAt, 6), DATE_TIME_FORMAT_WITHOUT_SEC),
        }
      })
    }
    const mapLocation = (l: VacancyLocation) => {
      const data = {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressCountry: l.country,
        }
      }
      if (l.country !== l.city) {
        // @ts-ignore
        data.address.addressRegion = l.city
      }
      return data
    }
    for (let vacancy of this.vacancies) {
      script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'JobPosting',
          title: vacancy.name,
          description: stripDescriptionTags(vacancy.description, { stripLinks: true }),
          applicantLocationRequirements: defaultLocations,
          ...((vacancy.positionRemote && !vacancy.positionOffice) && {
            jobLocationType: 'TELECOMMUTE',
          }),
          ...(vacancy.positionOffice && vacancy.locations?.length && {
            jobLocation: vacancy.locations.map(mapLocation)
          }),
          hiringOrganization: {
            '@type': 'Organization',
            name: vacancy.user.name,
            ...(vacancy.user.avatar?.src && { logo: vacancy.user.avatar.src }),
          },
          baseSalary: {
            '@type': 'MonetaryAmount',
            currency: 'USD',
            value: {
              '@type': 'QuantitativeValue',
              unitText: 'YEAR',
              ...(vacancy.salary_type === SalaryTypes.FIXED && { value: vacancy.salary_from }),
              ...(vacancy.salary_type === SalaryTypes.RANGE && { minValue: vacancy.salary_from, maxValue: vacancy.salary_to }),
            },
          },
          datePosted: formatDate(vacancy.publishedAt, 'YYYY-MM-DD'),
          validThrough: formatDate(addMonths(vacancy.publishedAt, 3), DATE_TIME_FORMAT_WITHOUT_SEC),
        }
      })
    }
    return {
      title: this.metaTitle,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.metaDescription,
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.metaTitle,
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.metaDescription,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/preview/preview.png`
        },
        {
          vmid: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          property: 'twitter:src',
          content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/preview/landing/twitter.png`
        },
      ],
      script,
      link: [this.canonicalLink]
    }
  },
})
