import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend<any, any, any, any>({
  computed: {
    ...mapGetters({
      vacancies: 'landing/vacancyLinks',
    }),
  },
})
