import Vue from 'vue'
import { FREELANCERS_LIST } from '@/constants/routes'

export default Vue.extend({
  data () {
    return {
      FREELANCERS_LIST,
    }
  }
})
