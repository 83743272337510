import Vue from 'vue'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import cloneDeep from 'lodash/cloneDeep'
import RoleSelect from '../RoleSelect/RoleSelect.vue'
import { RootState } from '@/store'
import { mapActions, mapGetters, mapState } from 'vuex'
import VacancyRole from '@/models-ts/vacancies/VacancyRole'
import { BROWSE_VACANCIES_BY_ROLE, VACANCIES } from '@/constants/routes'

const SORT_NEW = {
  name: 'Newest',
  field: 'date',
  dir: 'desc',
}

const SORT_OLD = {
  name: 'Oldest',
  field: 'date',
  dir: 'asc',
}

const SORT_OPTIONS = [
  SORT_NEW,
  SORT_OLD,
]

export default Vue.extend<any, any, any, any>({
  components: {
    RoleSelect
  },
  data () {
    return {
      sortOptions: SORT_OPTIONS,
      search: '',
      sortValue: SORT_NEW,
      role: null,
    }
  },
  computed: {
    ...mapState<RootState>({
      rolesLoaded: (state: RootState) => state.vacancyRoles.roles.isLoaded,
      roles: (state: RootState) => state.vacancyRoles.roles.value || [],
    }),
    ...mapGetters({
      getRoleByURL: 'vacancyRoles/getRoleByURL',
    }),
    isDefaultSort () {
      return this.sortValue?.dir === SORT_NEW.dir
    },
    selectedRole () {
      if (this.role && this.rolesLoaded) {
        for (const category of this.roles) {
          const role = category.primaryRoles.find((r: VacancyRole) => r.id === this.role)
          if (role) return role
        }
      }
    },
    tags () {
      const roleTag = this.selectedRole?.name
        ? [{
          text: `Role: ${this.selectedRole.name}`,
          type: 'role',
        }]
        : []
      const sortTags = !this.isDefaultSort
        ? [{
          text: `Sort by: ${this.sortValue?.name}`,
          type: 'sort',
        }]
        : []

      return [...roleTag, ...sortTags]
    },
    hasFilter () {
      return this.search ||
        (this.sortValue?.field && this.sortValue?.dir !== SORT_NEW.dir) ||
        this.role
    },
    filtersCount () {
      let count = this.role ? 1 : 0
      count = this.search ? count + 1 : count
      return this.sortValue?.field && this.sortValue?.dir !== SORT_NEW.dir ? count + 1 : count
    }
  },
  async mounted () {
    this.initFiltersFromQuery()
  },
  watch: {
    async $route (newR, oldR) {
      if (newR.query !== oldR.query) {
        this.initFiltersFromQuery()
      }
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    initFiltersFromQuery () {
      const {
        title = '',
        orderField = null,
        orderType = null,
        role = null
      } = this.$route.query
      this.search = title
      const sortValue = SORT_OPTIONS.find((s: any) => s.field === orderField && s.dir === orderType)
      if (this.$route.params.role) {
        this.role = this.getRoleByURL(this.$route.params.role)?.id
      } else {
        this.role = Number(role)
      }
      this.sortValue = sortValue || SORT_NEW
    },
    isFilterActive (filter: string) {
      return this.tags.some((tag: any) => tag.type === filter)
    },
    onClickReset () {
      this.search = ''
      this.sortValue = SORT_OPTIONS[0]
      this.role = null
      this.mapToQuery()
    },
    onInputSort (sort: any) {
      if (sort && sort?.name !== this.sortValue?.name) {
        this.sortValue = sort
        this.mapToQuery()
      }
    },
    onTagDelete ({ tag }: { tag: any }) {
      switch (tag.type) {
        case 'sort': {
          this.sortValue = null
          break
        }
        case 'role': {
          this.role = null
        }
      }
      this.mapToQuery()
    },
    onClearInput () {
      this.search = ''
      this.mapToQuery()
    },
    onSearchInput: debounce(function (this: any) {
      this.mapToQuery()
    }, 500),
    debouncedMTQ: debounce(function (this: any) {
      this.mapToQuery()
    }, 500),
    mapToQuery () {
      const filters = cloneDeep(pickBy({
        ...this.$route.query,
        title: this.search.trim(),
        role: this.role,
        orderField: this.sortValue?.field,
        orderType: this.sortValue?.dir,
        page: null,
      }, Boolean))
      let name = this.$route.name
      let params = {
        ...this.$route.params,
        savePosition: true,
      }
      this.$router.replace({
        name,
        params,
        query: filters,
      }).catch(() => {})
    },
    onClickOpenFilters () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "jobs-modals" */ '../FilterModal/FilterModal.vue'),
          title: 'Filters',
          props: {
          }
        }
      })
    },
  },
})
