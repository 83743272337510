import { render, staticRenderFns } from "./WeHelpSection.htm?vue&type=template&id=387ef803&scoped=true&"
import script from "./WeHelpSection.ts?vue&type=script&lang=js&"
export * from "./WeHelpSection.ts?vue&type=script&lang=js&"
import style0 from "./WeHelpSection.scss?vue&type=style&index=0&id=387ef803&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387ef803",
  null
  
)

export default component.exports