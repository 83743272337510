
export const PART_TIME = 1
export const FULL_TIME = 2

export const POSITION_TYPES = {
  [PART_TIME]: { id: 1, name: 'Part time' },
  [FULL_TIME]: { id: 2, name: 'Full time' },
}

export type PositionType = typeof PART_TIME | typeof FULL_TIME
