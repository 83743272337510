import Vue from 'vue'
import { VACANCY_ADD } from '@/constants/routes'

export default Vue.extend({
  data () {
    return {
      VACANCY_ADD,
    }
  }
})
