import { mapState } from 'vuex'
import Vue from 'vue'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import recaptchaMixin from '@/mixins/recaptchaMixin'
import snackMixin from '@/mixins/snackMixin'
import { SubscriptionType, createSubscription } from '@/api/subsciption'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { RootState } from '@/store'

export default Vue.extend({
  mixins: [responseMixin, recaptchaMixin, snackMixin],
  data () {
    return {
      email: '',
      isProcessing: false,
      loadingCaptcha: false,
      recaptchaTimeout: null,
      lazy: true,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
  },
  methods: {
    async onSubmit () {
      this.isProcessing = true
      this.errors!.clear()
      if (await this.$validator.validateAll()) {
        this.loadingCaptcha = true
        this.executeRecaptcha(this.onVerify)
        this.recaptchaTimeout = setTimeout(() => {
          this.loadingCaptcha = false
        }, 3000)
      } else {
        this.isProcessing = false
      }
    },
    async onVerify (reCaptcha: string) {
      if (this.recaptchaTimeout) {
        clearTimeout(this.recaptchaTimeout)
      }
      this.captchaCode = reCaptcha
      try {
        const successText = this.isLoggedIn
          ? 'Subscribed successfully!'
          : 'Email sent successfully! Check your inbox to confirm your subscription.'
        await notifiableRequest({
          request: async () => createSubscription({
            type: SubscriptionType.NEW_FULL_TIME_JOBS_REQUEST,
            email: this.email,
            reCaptcha,
          }),
          successText,
          failureText: 'Error submitting application',
        })
        googleAnalyticsV2.send({
          event: 'ftj-subscribe-success',
        })
      } finally {
        this.isProcessing = false
      }
    },
  }
})
