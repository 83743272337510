import { render, staticRenderFns } from "./FreelanceServices.htm?vue&type=template&id=298885ee&scoped=true&"
import script from "./FreelanceServices.ts?vue&type=script&lang=js&"
export * from "./FreelanceServices.ts?vue&type=script&lang=js&"
import style0 from "./FreelanceServices.scss?vue&type=style&index=0&id=298885ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298885ee",
  null
  
)

export default component.exports