import Vue from 'vue'
import { VACANCIES } from '@/constants/routes'

export default Vue.extend({
  data () {
    return {
      VACANCIES
    }
  }
})
